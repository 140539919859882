<template>
  <div>
    <v-container style="padding-top: 4rem">
      <div style="margin-bottom:10px">
<v-btn color="secondary" >
 Ga terug
    </v-btn>
</div>
      <div style="padding-bottom: 1rem">
        <v-btn
          color="primary"
          class="ma-2 white--text"
          @click="downloadPDF()"
          :loading="loading"
        >
          Rapportage uitdraaien
        </v-btn>
      </div>

      <div class="row">
        <div class="col-12 col-lg-3 mb-4">
          <div>
            <div class="link-box">
              <v-icon large color="green darken-2"> mdi-cart-variant </v-icon>
              <h2>{{ totalReservations.length }}</h2>
              <p class="link-box-subtitle">Aantal Reservingen</p>
               
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3 mb-4">
          <div class="link-box">
            <v-icon large color="error"> mdi-close-octagon-outline </v-icon>
            <h2>0</h2>
            <p class="link-box-subtitle">Aantal Annuleringen</p>
          </div>
        </div>

        <div class="col-12 col-lg-3 mb-4">
          <div class="link-box">
            <v-icon large color="blue darken-2"> mdi-account-multiple </v-icon>
            <h2>{{ users.length }}</h2>
            <p class="link-box-subtitle">Aantal Gebruikers</p>
          </div>
        </div>

        <div class="col-12 col-lg-3 mb-4">
          <div class="link-box">
            <v-icon large color="blue darken-2"> mdi-chair-rolling </v-icon>
            <h2>{{ offices.aantal }}</h2>
            <p class="link-box-subtitle">Aantal Flexplekken</p>
          </div>
        </div>

        
        <div class="col-12 col-lg-3 mb-4">
          <div class="link-box">
            <v-icon large color="error"> mdi-account-multiple </v-icon>
            <h2>0</h2>
            <p class="link-box-subtitle">Rol niet toegewezen</p>
          </div>
        </div>
          <div class="col-12 col-lg-3 mb-4">
          <div class="link-box">
            <v-icon large color="primary"> mdi-cash </v-icon>
            <h2>0</h2>
            <p class="link-box-subtitle">Omzet</p>
          </div>
        </div>
         <div class="col-12 col-lg-3 mb-4">
          <div class="link-box">
            <v-icon large color="primary"> mdi-ticket </v-icon>
            <h2>0</h2>
            <p class="link-box-subtitle">Aantal meldingen</p>
          </div>
        </div>
      </div>
    </v-container>
    <VueHtml2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :paginate-elements-by-height="1400"
      filename="Analytics"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="800px"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <img
          class="logo"
          src="@/assets/logo.png"
          alt="KantoorFlex logo"
          title="Flexplekken in Nederland"
          height="150"
          width="250"
        />
        <h1 style="text-align:center;color:#64b5f6;margin-left:50px">
          Data Raport
        </h1>
        <div style="margin: 30px">
          <p><strong>Aantal Gebruikers: </strong> {{ users.length }}</p>
          <p>
            <strong>Aantal Flexplekken: </strong>
            {{ offices.aantal ? offices.aantal : 0 }}
          </p>

               <p>
            <strong>Aantal Reserveringen: </strong>
            {{ totalReservations.length ? totalReservations.length : 0 }}
          </p>


   
        </div>
      </section>
    </VueHtml2pdf>
  </div>
</template>

<script>

import apiUser from '../api/apiUser'
import api from '../api/api'
import VueHtml2pdf from "vue-html2pdf";
export default {

  components: {
    VueHtml2pdf,
  },
  data() {
    return {
      offices: [],
      users: [],
      loading: false,
      totalReservations:[],

    };
  },

  async mounted() {
        apiUser.getUsers().then((data) => {
      this.users = data;
    });

         api.getTotalOffices().then((data) => {
      this.offices = data;
    });
         api.getTotalReservations().then((data) => {
      this.totalReservations = data;
    });
   
  },
  methods: {
    async downloadPDF() {
      this.loading = true;
      await this.$refs.html2Pdf.generatePdf();
      this.loading = false;
    },
     
  },
};
</script>

<style scoped>
.router-link {
  color: black;
  text-decoration: none;
}
.link-box {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 5px 8px 0 rgba(0, 0, 0, 0.05);
  padding: 20px;
  font-size: 16px;
  text-align: center;
  /* min-height:100%; */
}
.link-box p {
  margin: 0px 0px 10px 0px;
}
.link-box img {
  height: 40px;
  margin-bottom: 10px;
}
.link-box-title {
  color: black;
  font-weight: bold;
  font-size: 18px;
}
.link-box-subtitle {
  color: black;
  font-size: 18px;
  margin-bottom: 10px;
}
</style>
