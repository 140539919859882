<template>
  <div>
    <v-container style="padding-top: 4rem">
      <div style="margin-bottom:10px">
<v-btn color="primary" >
 Ga terug
    </v-btn>
</div>
      <v-row style="margin-top: 15px">
        <v-dialog class="text-center" v-model="dialog" width="400">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              fab
              dark
              color="indigo"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>

          <v-card style="margin-top:20px">
            <v-container>
              <v-card-title> Gebruiker aanmaken </v-card-title>
              <v-row>
                <v-card-text class="text-center">
                  <v-form>
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-text-field
                          v-model="user.name"
                          outlined
                          label="First name"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-text-field
                          v-model="user.phoneNumber"
                          placeholder="+310612345678"
                          outlined
                          label="Telefoonnummer"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-text-field
                          v-model="user.email"
                          outlined
                          label="E-mail"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="12">
                        <v-text-field
                          v-model="user.password"
                          outlined
                          label="Wachtwoord"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="12">
                        <v-select
                          v-model="user.role"
                          :items="roles"
                          label="Rol selecteren"
                          outlined
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-row>
            </v-container>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="error" @click="dialog = false">
                Afsluiten
              </v-btn>

              <v-btn
                v-if="user.role == 'Klant'"
                color="primary"
                @click="createCustomer()"
              >
                Klant aanmaken
              </v-btn>

              <v-btn
                v-if="user.role == 'Makelaar'"
                color="primary"
                @click="createMakelaar()"
              >
                Makelaar aanmaken
              </v-btn>

              <v-btn
                v-if="user.role == 'Medewerker'"
                color="primary"
                @click="createMedewerker()"
              >
                Medewerker aanmaken
              </v-btn>

              <v-btn
                v-if="user.role == 'Administrator'"
                color="primary"
                @click="createAdmin()"
              >
                Administrator aanmaken
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row>
        <v-col>
          <h5><b> Type rol</b></h5>
          <v-checkbox
            v-model="filter.ADMIN"
            label="Admin"
            color="primary"
            value="primary"
            hide-details
            class="shrink mr-2"
          ></v-checkbox>
          <v-checkbox
            v-model="filter.MAKELAAR"
            label="Makelaar"
            color="primary"
            value="secondary"
            hide-details
            class="shrink mr-2"
          ></v-checkbox>

          <v-checkbox
            v-model="filter.WERKNEMER"
            label="Werknemer"
            color="primary"
            value="secondary"
            hide-details
            class="shrink mr-2"
          ></v-checkbox>

          <v-checkbox
            v-model="filter.CUSTOMER"
            label="Klant"
            color="primary"
            value="secondary"
            hide-details
            class="shrink mr-2"
          ></v-checkbox>
          <v-checkbox
            v-model="filter.UNDETERMINED"
            label="Niet Toegewezen"
            color="primary"
            value="secondary"
            hide-details
            class="shrink mr-2"
          ></v-checkbox>
        </v-col>

        <v-col cols="10">
          <v-data-table
            :headers="headers"
            :items="filteredItems"
            disable-pagination
            :hide-default-footer="true"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Alle gebruikers</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-slot:[`item.Actions`]="{ item }">
   
                <v-icon
                  small
                  class="mr-2"
                  color="red"
                  v-if="item.role != 'UNDETERMINED'"
                  @click="changeRole(item.uid)"
                >
                  mdi-close-circle
                </v-icon>
             
             
                <v-icon
                  small
                  class="mr-2"
                  color="green"
                  v-if="item.role == 'UNDETERMINED'"
                  @click="changeRoleToCustomer(item.uid)"
                >
                  mdi-check-circle-outline
                </v-icon>

    
              <v-icon small @click="deleteUser(item.uid)">
                mdi-delete
              </v-icon>
 
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="resetButton()">
                Reset
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import apiUser from '../api/apiUser'
import axios from "axios";
export default {
  
  data: () => ({
    role: "UNDETERMINED",
    filter: {
      ADMIN: false,
      MAKELAAR: false,
      CUSTOMER: false,
      WERKNEMER: false,
      UNDETERMINED: false,
    },
    dialog: false,
    dialogDelete: false,
    roles: ["Medewerker", "Klant", "Makelaar", "Administrator"],

    user: {
      name: "",
      email: "",
      phoneNumber: "",
      password: "",
      role: "",
    },
    items: [],
    headers: [
      {
        text: "Naam",
        value: "displayName",

        sortable: false,
      },

      {
        text: "Email",
        value: "email",
        sortable: true,
      },
      {
        text: "Telefoonnummer",
        value: "phone",
        sortable: true,
      },

      {
        text: "Rol",
        value: "role",
        sortable: true,
      },
      {
        text: "Actions",
        value: "Actions",
        sortable: false,
      },
    ],
  }),

  computed: {
    filteredItems() {
      let items = this.items;

      if (
        this.filter.ADMIN ||
        this.filter.WERKNEMER ||
        this.filter.MAKELAAR ||
        this.filter.CUSTOMER ||
        this.filter.UNDETERMINED
      ) {
        if (
          this.filter.ADMIN !== this.filter.WERKNEMER ||
          this.filter.MAKELAAR ||
          this.filter.CUSTOMER ||
          this.filter.UNDETERMINED
        ) {
          if (
            this.filter.WERKNEMER !== this.filter.ADMIN ||
            this.filter.MAKELAAR ||
            this.filter.CUSTOMER ||
            this.filter.UNDETERMINED
          ) {
            if (
              this.filter.MAKELAAR !== this.filter.WERKNEMER ||
              this.filter.ADMIN ||
              this.filter.CUSTOMER ||
              this.filter.UNDETERMINED
            ) {
              if (
                this.filter.CUSTOMER !== this.filter.WERKNEMER ||
                this.filter.MAKELAAR ||
                this.filter.ADMIN ||
                this.filter.UNDETERMINED
              ) {
                if (
                  this.filter.UNDETERMINED !== this.filter.WERKNEMER ||
                  this.filter.MAKELAAR ||
                  this.filter.ADMIN ||
                  this.filter.CUSTOMER
                ) {
                  if (this.filter.ADMIN) {
                    items = this.items.filter((item) => item.role === "ADMIN");
                  }
                  if (this.filter.WERKNEMER) {
                    items = this.items.filter(
                      (item) => item.role === "WERKNEMER"
                    );
                  }
                  if (this.filter.MAKELAAR) {
                    items = this.items.filter(
                      (item) => item.role === "MAKELAAR"
                    );
                  }
                  if (this.filter.CUSTOMER) {
                    items = this.items.filter(
                      (item) => item.role === "CUSTOMER"
                    );
                  }
                  if (this.filter.UNDETERMINED) {
                    items = this.items.filter(
                      (item) => item.role === "UNDETERMINED"
                    );
                  }
                }
              }
            }
          }
        }
      }
      return items;
    },
  },

  async mounted() {
        apiUser.getUsers().then((data) => {
      this.bolorders = data;
    });
    await this.items();
  },

  methods: {
    resetForm() {
      this.name = "";
      this.email = "";
      this.password = "";
      this.phoneNumber = "";
    },
    resetButton() {
      this.filter.ADMIN = false;
      this.filter.MAKELAAR = false;
      this.filter.CUSTOMER = false;
      this.filter.WERKNEMER = false;
      this.filter.UNDETERMINED = false;
    },
    async deleteUser(uid) {
      const result = await this.$swal.fire({
        title: "Ben je zeker?",
        text: "Je kunt dit niet herstellen!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijder",
      });
      if (result.isConfirmed) {
        await axios.delete(`https://server.kantoorflex.nl/api/rest/v2/users/${uid}`);
        this.$swal.fire("Verwijderd!", "Gebruiker is verwijderd.", "success");
        await this.getUsers();
      }
    },
    async createMedewerker() {
      try {
        await axios.post(`https://back-end.mcmakelaardij.nl/api/rest/v2/medewerker`, {
          name: this.user.name,
          email: this.user.email,
          password: this.user.password,
          phoneNumber: this.user.phoneNumber,
        });
        this.$swal.fire("Dankje!", "Aangemaakt", "success");
        this.resetForm();
        this.getUsers();
        this.dialog = false;
      } catch (error) {
        this.errored = true;
        console.log(error);
        this.$swal.fire("Error!", error.message, "error");
        throw error;
      }
    },

    async createCustomer() {
      try {
        await axios.post(`https://server.kantoorflex.nl/api/rest/v2/customer`, {
          name: this.user.name,
          email: this.user.email,
          password: this.user.password,
          phoneNumber: this.user.phoneNumber,
        });
        this.$swal.fire("Dankje!", "Aangemaakt", "success");
        this.resetForm();
        this.getUsers();
        this.dialog = false;
      } catch (error) {
        this.errored = true;
        console.log(error);
        this.$swal.fire("Error!", error.message, "error");
        throw error;
      }
    },

    async createMakelaar() {
      try {
        await axios.post(`https://server.kantoorflex.nl/api/rest/v2/makelaar`, {
          name: this.user.name,
          email: this.user.email,
          password: this.user.password,
          phoneNumber: this.user.phoneNumber,
        });
        this.$swal.fire("Dankje!", "Aangemaakt", "success");
        this.resetForm();
        this.getUsers();
        this.dialog = false;
      } catch (error) {
        this.errored = true;
        console.log(error);
        this.$swal.fire("Error!", error.message, "error");
        throw error;
      }
    },

    async createAdmin() {
      try {
        await axios.post(`https://server.kantoorflex.nl/api/rest/v2/administrator`, {
          name: this.user.name,
          email: this.user.email,
          password: this.user.password,
          phoneNumber: this.user.phoneNumber,
        });
        this.$swal.fire("Dankje!", "Aangemaakt", "success");
        this.resetForm();
        this.getUsers();
        this.dialog = false;
      } catch (error) {
        this.errored = true;
        console.log(error);
        this.$swal.fire("Error!", error.message, "error");
        throw error;
      }
    },
    async changeRole(uid) {
      await axios.put(`https://server.kantoorflex.nl/api/rest/v2/users/${uid}`, {
        name: "UNDETERMINED"
      });

      this.$swal.fire("Gewijzigd!", "", "success");
      await this.getUsers();
    },
    async changeRoleToCustomer(uid) {
      await axios.put(`https://server.kantoorflex.nl/api/rest/v2/users/${uid}`, {
        name: "MAKELAAR",
      });

      this.$swal.fire("Gewijzigd!", "", "success");
      await this.getUsers();
    },
       
  },
};
</script>
