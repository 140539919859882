<template>
  <div>
    <Data />
  </div>
</template>

<script>
import Data from "@/components/admin/Data";

export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Data,
  },
  metaInfo: {
    title: "Data bekijken | flexplekken in Nederland ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>
